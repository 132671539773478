import PropTypes from 'prop-types';
import { Icon } from '@iconify-icon/react';

const propTypes = {
  allowedFileTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
const defaultProps = {};

function FieldDragDropNotice({ allowedFileTypes }) {
  return (
    <div className="field-drag-drop-notice">
      <div className="field-drag-drop-notice__icon">
        <Icon icon="fa:upload" />
      </div>
      <div className="field-drag-drop-notice__main">
        <div>Drag and drop here</div>
        <div className="text-muted">{allowedFileTypes.map((f) => f.extension).join(', ')}</div>
      </div>
    </div>
  );
}

FieldDragDropNotice.propTypes = propTypes;
FieldDragDropNotice.defaultProps = defaultProps;

export default FieldDragDropNotice;
